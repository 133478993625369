/* Base styles */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeInSide {
  from {
    opacity: 0;
    transform: translateX(+100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

* {
  box-sizing: border-box;
}
body, html {
  margin: 0;
  background-color: #1e1e1e;
  padding: 0;
}

.top-wrapper {

  grid-area: top;
  background-image: url("../public/shop.jpeg"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "main main side1 side1"
    "main main side2 side2"
    "main main side3 side3";
}
.grid-container {
   
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  box-sizing: border-box;

  grid-template-rows: auto;
  grid-template-areas:
    "top top top top"
    "content5 content5 content6 content6"
    "content7 content7 content8 content8"
    "content9 content9 content10 content10"
    "content11 content12 content13 content14";
}

/* Grid item styles with background colors */
.main-content {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: main;
  animation: fadeInUp 1s ease-out forwards;  
}
.main-content img{
  max-width: 100%;}

  .main-content img:hover {
    transform: scale(1.2); /* Increase size */
    filter: brightness(1.3); /* Make it slightly brighter */
  }
.side-content-1 {
  display: flex;
  align-items:center;
  justify-content: center;
  grid-area: side1;
  padding:50px;
  animation: fadeInSide 1s ease-out forwards;  


}
.side-content-1 div{
  background-color: #1e1e1e;
  color:white;
  padding: 20px;
  border-radius: 10px;
}
.side-content-1 p{
  padding: 20px;
  font-size: 17px;
}

.side-content-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  grid-area: side2;
  
}

.side-content-3 {
  padding:40px;
  grid-area: side3;
  animation: fadeInSide 1s ease-out forwards;  

}
.side-content-3 ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding-right:40px;

}
.side-content-3 ul li{
  background-color:#ffffff;
  padding: 10px;
  border-radius:50px;
}

.side-content-3 ul li:hover {
  transform: scale(1.1); /* Increase size */
  filter: brightness(1.2); /* Make it slightly brighter */
}
.content{
  padding:40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content img{
  max-width: 100%;
  border-radius: 10px;
}
.content img:hover {
  transform: scale(1.1); /* Increase size */
  filter: brightness(1.3); /* Make it slightly brighter */
}
.content div{
  border-radius: 10px;
  padding:20px;
  background-color: #292929;
  color:white;
}
.content p{
  font-size:20px;
}
.content-5 {
  grid-area: content5;
}
.content-6 {
  grid-area: content6;
}
.content-7 {
  grid-area: content7;
}
.content-8 {
  grid-area: content8;
}

.content-9 {
  padding:40px;
  grid-area: content9;
  color:white;
  
}
.buy-step h2 {
  padding-left:15px;
}
.buy-step p {
  padding-left:28px;

  font-size: 16px;
  line-height: 1.5;
}
.buy-step span{
  font-weight: bold;
}
.buy-step {
  cursor: pointer; 

  background-color: #292929; /* Slightly lighter than the grid background for contrast */
  color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  margin:10px;
}

.laststep{
  width:150px;
  transform: scale(1.3);
}

.content-10 {
  padding-top:80px;
  grid-area: content10;
  
}
.content-10 img{
  border-radius:5px;
  max-width: 100%;
}



.content-11 {
  grid-area: content11;
}
.content-12 {
  grid-area: content12;
}
.content-13 {
  grid-area: content13;
}
.content-14 {
  grid-area: content14;
}

.roadmap{
  padding:50px;
}
.roadmap div{
  padding:20px;
  background-color:#292929;
  border-radius: 10px;
  color:white;
}


/* Responsive design for mobile view */
@media only screen and (max-width: 425px) {
  .App{
    overflow-x: hidden;

  }
  .top-wrapper {
    grid-template-columns: 1fr;
    grid-template-columns: minmax(0,100vw);
    
    grid-template-areas:
      "main"
      "side1"
      "side2"
      "side3";
      
  }
  .grid-container {
    grid-template-columns: 1fr;
    
    grid-template-areas:
      "top"
      "top"
      "top"
      "top"
      "content5"
      "content6"
      "content7"
      "content8"
      "content9"
      "content10"
      "content11"
      "content12"
      "content13"
      "content14";
      
  }
  
}
